import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { maxLengthValidation } from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  updateCommunityGatheringById,
  viewCommunityGatheringById,
} from '../../services/content.service';
import { LANGUAGES } from '../../config/const.config';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const TranslateCommunityGatherings = ({
  title,
  dataId,
  onCancel,
  onSuccess,
}) => {
  const defaultValues = {};
  const isCommunityGathering = true;
  for (let i = 0; i < LANGUAGES.length; i += 1) {
    defaultValues[`title_${LANGUAGES[i].value}`] = '';
    defaultValues[`description_${LANGUAGES[i].value}`] = '';
    defaultValues[`thumbnail_${LANGUAGES[i].value}`] = '';
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [communityGatheringData, setCommunityGatheringData] = useState([]);
  // const [renderImage, setRenderImage] = useState(false);

  const showUpdatedImage = () => {
    // if (rerender) {
    //   setLoading(true);
    // }
    // setRenderImage(rerender);
  };
  const setImageUrlCallBack = (v) => {
    setCommunityGatheringData((prevState) => ({
      ...prevState,
      [v.languageCode]: {
        ...prevState[v.languageCode],
        thumbnail: v.imageUrl,
      },
    }));
  };

  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const updateExistingData = (payload) => {
    updateCommunityGatheringById(dataId, payload, 'lang')
      .then(() => {
        onSuccess('Gathering details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {};
    for (let i = 0; i < LANGUAGES.length; i += 1) {
      payload[`title_${LANGUAGES[i].value}`] =
        data[`title_${LANGUAGES[i].value}`];
      payload[`findMoreBtnText_${LANGUAGES[i].value}`] =
        data[`findMoreBtnText_${LANGUAGES[i].value}`];
      payload[`bookTicketBtnText_${LANGUAGES[i].value}`] =
        data[`bookTicketBtnText_${LANGUAGES[i].value}`];
    }

    if (dataId) {
      updateExistingData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewCommunityGatheringById(dataId, 'lang')
      .then((res) => {
        // Fill form values
        setCommunityGatheringData(res.data);
        for (let i = 0; i < LANGUAGES.length; i += 1) {
          if (res.data[`${LANGUAGES[i].value}`]) {
            setValue(
              `title_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].title || ''
            );
            setValue(
              `findMoreBtnText_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].findMoreBtnText || ''
            );
            setValue(
              `bookTicketBtnText_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].bookTicketBtnText || ''
            );
            setValue(
              `thumbnail_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].banner || ''
            );
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="upload-assets-form" onSubmit={handleSubmit(onFormSubmit)}>
            {LANGUAGES.map((item, index) => (
              <Grid container spacing={2} key={`title${item.value}`}>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider
                    textAlign="center"
                    variant="fullWidth"
                    sx={{
                      fontWeight: 'bold',
                      mt: index === 0 ? 0 : 2,
                    }}
                  >
                    {item.label} Translation
                  </Divider>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name={`title_${item.value}`}
                    control={control}
                    rules={{
                      ...{ ...maxLengthValidation(80) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Gathering Title (${item.value})`}
                        type="text"
                        error={!!errors[`title_${item.value}`]}
                        helperText={
                          errors[`title_${item.value}`]?.message || null
                        }
                        fullWidth
                        inputProps={{ maxLength: 80 }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name={`findMoreBtnText_${item.value}`}
                    control={control}
                    rules={{
                      ...maxLengthValidation(50),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Find more button text (${item.value})`}
                        type="text"
                        error={!!errors[`findMoreBtnText_${item.value}`]}
                        helperText={
                          errors[`findMoreBtnText_${item.value}`]?.message ||
                          null
                        }
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name={`bookTicketBtnText_${item.value}`}
                    control={control}
                    rules={{
                      ...maxLengthValidation(50),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Ticket button text (${item.value})`}
                        type="text"
                        error={!!errors[`bookTicketBtnText_${item.value}`]}
                        helperText={
                          errors[`bookTicketBtnText_${item.value}`]?.message ||
                          null
                        }
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  />
                </Grid>

                <Box
                  component="section"
                  sx={{
                    p: 3,
                    ml: 2,
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    width: '100%',
                    marginTop: 3,
                    padding: '10px',
                  }}
                >
                  <ImageUpload
                    title="Thumbnail"
                    dataId={dataId}
                    showToastMsg={showToastMsg}
                    setSnackbarInfo={setSnackbarInfo}
                    videoData={
                      communityGatheringData[item.value] || {
                        title: '',
                        banner: '',
                        languageCode: item.value,
                      }
                    }
                    loading={loading}
                    showUpdatedImage={showUpdatedImage}
                    setImageUrlCallBack={setImageUrlCallBack}
                    isTranslation
                    isCommunityGathering={isCommunityGathering}
                  />
                </Box>
              </Grid>
            ))}
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="upload-assets-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

TranslateCommunityGatherings.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TranslateCommunityGatherings;
