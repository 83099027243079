/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  FormControl,
  InputAdornment,
} from '@mui/material';
import dayjs from 'dayjs';
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';

import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewVideo,
  getVimeoEventDetail,
  updateVideoById,
  viewVideoById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import VideoTypes from '../form-elements/video-types.component';
import {
  CONTENT_ALIAS,
  EVENT_TYPE,
  FREQUENCY_TYPE,
  MULTIPLE_CAT_PER_VIDEO,
  VIDEO_TYPES,
} from '../../config/const.config';
import Datetimepicker from '../form-elements/datetimepicker.component';
import Datepicker from '../form-elements/datepicker.component';
import Category from '../form-elements/category.component';
import RecurringType from '../form-elements/recurring-type.component';
import FrequencyTypes from '../form-elements/frequency-type.component';
import DayNames from '../form-elements/day-name.component';
import DateList from '../form-elements/date-list.component';
import { getCurrentTimestamp } from '../../utils/datetime.util';
import WeekNumber from '../form-elements/week-number.component';
import ConfirmPopup from '../common/confirm-popup.component';
import EventType from '../form-elements/event-type.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditVideos = ({ title, dataId, onCancel, onSuccess, options }) => {
  const {
    control,
    formState: { errors },
    watch,
    unregister,
    handleSubmit,
    setValue,
    setError,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      status: null,
      duration: null,
      videoUrl: '',
      thumbnail: '',
      heroImage: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isDateDisable, setIsDateDisable] = useState(false);
  const eventStartTimeRef = useRef(null);
  const videoTypeWatch = watch('videoType');
  const vimeoLiveEventID = watch('vimeoLiveEventID');
  const watchRecurring = watch('recurringType');
  const watchFrequency = watch('frequencyType');
  const watchEvent = watch('eventType');
  const recurringInfo = useRef({
    eventStartDate: null,
    isRecurring: null,
    frequencyType: null,
    recurringDays: null,
    selectedWeeks: null,
    recurringEndDate: null,
  });
  const editPayload = useRef(null);
  let eventStartDateTimeGMT = 0;
  let recurringEndDateTimeGMT = 0;
  const eventEndTimeGMT = null;
  const currentTime = getCurrentTimestamp();
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const optionsEditor = [
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    '|',
    'outdent',
    'indent',
    'align',
    '|',
    'hr',
    '|',
    'fullsize',
    'brush',
    '|',
    'table',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    '|',
    'source',
  ];
  const config = useMemo(
    () => ({
      defaultMode: 1,
      readonly: false,
      tabIndex: -1,
      toolbar: true,
      placeholder: 'Start typing...',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1,
      buttons: optionsEditor,
      buttonsMD: optionsEditor,
      buttonsSM: optionsEditor,
      buttonsXS: optionsEditor,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      minHeight: 350,
      useNativeTooltip: false,
      // language: 'de',
      // autofocus: true,
      // zIndex: 0,
      // toolbarAdaptive: false,
    }),
    []
  );

  const currentDate = dayjs();
  const maxDate = currentDate.add(2, 'year');

  const handleDayChange = (day) => {
    setSelectedDays((prev) =>
      prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
    );
  };

  const handleWeekChange = (week) => {
    setSelectedWeeks((prev) =>
      prev.includes(week) ? prev.filter((d) => d !== week) : [...prev, week]
    );
  };

  const saveNewData = (payload) => {
    createNewVideo(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.VIDEO_SINGULAR} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };
  const vimeoEventDetail = () => {
    if (vimeoLiveEventID) {
      getVimeoEventDetail(vimeoLiveEventID)
        .then((res) => {
          // Fill form values
          setValue('title', res.data?.title || '');
          setValue('description', res.data?.description || '');
          setValue('thumbnail', res.data?.thumbnail || '');
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    } else {
      setError('vimeoLiveEventID', {
        message: validationRules.REQUIRED.required.message,
      });
    }
  };

  const updateData = (payload) => {
    updateVideoById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.VIDEO_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    if (
      (recurringInfo.current.isRecurring === 1 &&
        payload.eventStartDate !== recurringInfo.current.eventStartDate) ||
      (recurringInfo.current.isRecurring === 1 &&
        payload.recurringEndDateTime !==
          recurringInfo.current.recurringEndDate) ||
      (recurringInfo.current.isRecurring === 1 &&
        payload.isRecurring !== recurringInfo.current.isRecurring) ||
      (recurringInfo.current.isRecurring === 1 &&
        payload.frequencyType !== recurringInfo.current.frequencyType) ||
      (recurringInfo.current.isRecurring === 1 &&
        JSON.stringify(recurringInfo.current.recurringDays.sort()) !==
          JSON.stringify(payload.recurringDays.sort())) ||
      (recurringInfo.current.isRecurring === 1 &&
        recurringInfo.current.frequencyType === FREQUENCY_TYPE.WEEKLY.value &&
        JSON.stringify(recurringInfo.current.selectedWeeks.sort()) !==
          JSON.stringify(payload.weekNumbers.sort()))
    ) {
      editPayload.current = payload;
      setOpenPopUp(true);
    } else {
      updateData(payload);
    }
  };

  const handleSuccess = () => {
    updateData(editPayload.current);
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const categoriesIdStrArr = [];
    if (!MULTIPLE_CAT_PER_VIDEO) {
      categoriesIdStrArr.push(data.category);
    }

    if (videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value) {
      eventStartDateTimeGMT =
        Date.parse(data.eventStartDateTime?.$d.toISOString()) / 1000;
      recurringEndDateTimeGMT =
        Date.parse(data.recurringEndDateTime?.$d.toISOString()) / 1000;

      if (
        eventStartDateTimeGMT <= currentTime &&
        eventStartDateTimeGMT !== eventStartTimeRef.current
      ) {
        setTimeout(() => {
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message: 'Event start date must be greater than current time.',
          });
        }, 100);
        setFormSubmitted(false);
        return;
      }
    }

    const payload = {
      title: data.title || '',
      description: data.description || '',
      longDescription: content || '',
      category: categoriesIdStrArr || [],
      videoType: data.videoType || '',
      ctaText: data.ctaButtonText || '',
      ctaUrl: data.ctaButtonUrl || '',
      eventType:
        data.videoType === VIDEO_TYPES.LIVE_EVENT.value
          ? data.eventType || null
          : null,
      vimeoLiveEventID:
        (data.videoType === VIDEO_TYPES.LIVE_EVENT.value &&
        data.eventType === EVENT_TYPE.LIVE.value
          ? data.vimeoLiveEventID
          : '') || '',
      zoomLink:
        (data.videoType === VIDEO_TYPES.LIVE_EVENT.value &&
        data.eventType === EVENT_TYPE.ZOOM.value
          ? data.zoomLink
          : null) || null,
      zoomPassword:
        data.videoType === VIDEO_TYPES.LIVE_EVENT.value &&
        data.eventType === EVENT_TYPE.ZOOM.value
          ? data.zoomPassword || ''
          : '',
      eventStartDate: eventStartDateTimeGMT || 0,
      eventDuration: data.eventDuration || 0,
      recurringEndDateTime:
        data.recurringType === 1 ? recurringEndDateTimeGMT || null : null,
      eventExpiryDate: eventEndTimeGMT || null,
      status: data.status,
      isRecurring: data.recurringType || 0,
      location: data.location || null,
      frequencyType:
        data.recurringType === 1 ? data.frequencyType || null : null,
      recurringDays:
        data.recurringType === 0
          ? null
          : (data.frequencyType === FREQUENCY_TYPE.WEEKLY.value
              ? selectedDays
              : data.monthDays) || null,
      weekNumbers:
        data.frequencyType === FREQUENCY_TYPE.WEEKLY.value
          ? selectedWeeks
          : null,
      duration: data.duration || 0,
      videoUrl: data.videoUrl || '',
      thumbnail: data.thumbnail || '',
      heroImage: data.heroImage || '',
    };

    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value) {
      unregister('videoUrl');
      unregister('duration');
    } else {
      unregister('longDescription');
    }
  }, [videoTypeWatch]);

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewVideoById(dataId)
      .then((res) => {
        eventStartTimeRef.current = res.data?.eventStartDate;
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('longDescription', res.data?.longDescription || '');
        setContent(res.data?.longDescription || '');
        setValue(
          'videoType',
          res.data?.videoType.toUpperCase().replace(/ /g, '_') || null
        );

        let selCat = null;
        if (res.data?.category) {
          selCat = {
            id: res.data?.category?.categoryId,
            name: res.data?.category?.categoryName,
          };
        }

        if (res.data?.videoType === VIDEO_TYPES.TRAINING.value) {
          setValue('category', selCat);
        }

        setValue('status', res.data?.status || 0);
        setValue('duration', res.data?.duration || 0);
        setValue('videoUrl', res.data?.videoUrl || '');
        setValue('thumbnail', res.data?.thumbnail || '');
        setValue('heroImage', res.data?.heroImage || '');
        setValue('eventType', res.data?.eventType || null);
        setValue('ctaButtonText', res.data?.ctaText || null);
        setValue('ctaButtonUrl', res.data?.ctaUrl || null);
        if (res.data?.eventType === EVENT_TYPE.ZOOM.value) {
          setValue('zoomLink', res.data?.zoomLink || null);
          setValue('zoomPassword', res.data?.zoomPassword || null);
        }
        setValue('recurringType', res.data?.isRecurring || 0);
        setValue(
          'eventExpiryDate',
          (res.data?.eventExpiryDate &&
            dayjs.unix(res.data?.eventExpiryDate)) ||
            null
        );
        setValue('location', res.data?.location || null);
        setValue('frequencyType', res.data?.frequencyType || null);
        if (res.data?.frequencyType === FREQUENCY_TYPE.WEEKLY.value) {
          setSelectedDays(res.data?.recurringDays || null);
          setSelectedWeeks(res.data?.weekNumbers || null);
          recurringInfo.current.recurringDays = res.data?.recurringDays;
          recurringInfo.current.selectedWeeks = res.data?.weekNumbers;
        } else if (res.data?.frequencyType === FREQUENCY_TYPE.MONTHLY.value) {
          setValue('monthDays', res.data?.recurringDays || null);
          recurringInfo.current.recurringDays = res.data?.recurringDays;
        }
        if (res.data?.eventType === EVENT_TYPE.LIVE.value) {
          setValue('vimeoLiveEventID', res.data?.vimeoLiveEventID || '');
        }
        setValue(
          'eventStartDateTime',
          dayjs.unix(res.data?.eventStartDate) || 0
        );
        setValue('eventDuration', res.data?.eventDuration || 0);
        if (res.data?.recurringEndDateTime) {
          setValue(
            'recurringEndDateTime',
            dayjs.unix(res.data?.recurringEndDateTime)
          );
        }
        recurringInfo.current.eventStartDate = res.data?.eventStartDate;
        recurringInfo.current.isRecurring = res.data?.isRecurring;
        recurringInfo.current.frequencyType = res.data?.frequencyType;
        recurringInfo.current.recurringEndDate = res.data?.recurringEndDateTime;
        if (res.data?.isRecurring === 0) {
          const time = res.data.eventStartDate + res.data.eventDuration * 60;
          if (time < currentTime) {
            setIsDateDisable(true);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.VIDEO_SINGULAR} Title`}
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ ...maxLengthValidation(2500) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 2500 }}
                      multiline
                      rows={3}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="videoType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <VideoTypes
                      id="videoType"
                      name="videoType"
                      label="Video Type"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.videoType?.message || ''}
                      disabled={!!dataId}
                      searchList={false}
                    />
                  )}
                />
              </Grid>

              {!MULTIPLE_CAT_PER_VIDEO &&
                videoTypeWatch === VIDEO_TYPES.TRAINING.value && (
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="category"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Category
                          id="category"
                          name="category"
                          label={`Select ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
                          defaultValue={value}
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.v?.message || ''}
                          options={options}
                          disabled={!!dataId}
                          type={['CATEGORY']}
                        />
                      )}
                    />
                  </Grid>
                )}

              {videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="ctaButtonText"
                      control={control}
                      rules={{
                        ...{ ...maxLengthValidation(255) },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="CTA Button Text"
                          type="text"
                          error={!!errors.ctaButtonText}
                          helperText={errors?.ctaButtonText?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 80 }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="ctaButtonUrl"
                      control={control}
                      rules={{
                        ...validationRules.OPTIONAL_URL,
                        ...{ ...maxLengthValidation(255) },
                        validate: {
                          startsWithProd: (value) => {
                            if (!value) {
                              return true;
                            }
                            if (
                              value?.startsWith('https') ||
                              value?.startsWith('http')
                            ) {
                              return true;
                            }
                            return "URL must start with 'https' or 'http'";
                          },
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="CTA Button Url"
                          type="text"
                          error={!!errors.ctaButtonUrl}
                          helperText={errors?.ctaButtonUrl?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name="longDescription"
                        control={control}
                        render={({ field }) => (
                          <JoditEditor
                            {...field}
                            label="Long Description"
                            ref={editor}
                            config={config}
                            tabIndex={-1}
                            onBlur={(newContent) => setContent(newContent)}
                            onChange={(newContent) => setContent(newContent)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="eventType"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <EventType
                          id="eventType"
                          name="eventType"
                          label="Event Type"
                          defaultValue={value}
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.eventType?.message || ''}
                          disabled={!!dataId}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value &&
                watchEvent === EVENT_TYPE.LIVE.value && (
                  <>
                    <Grid item xs={12} sm={4.13} md={4.13}>
                      <Controller
                        name="vimeoLiveEventID"
                        control={control}
                        rules={{
                          ...validationRules.REQUIRED,
                          ...{ ...maxLengthValidation(255) },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Vimeo Live Event Id"
                            type="text"
                            error={!!errors.vimeoLiveEventID}
                            helperText={
                              errors?.vimeoLiveEventID?.message || null
                            }
                            fullWidth
                            inputProps={{ maxLength: 255 }}
                          />
                        )}
                      />
                    </Grid>

                    <DialogActions
                      sx={{ justifyContent: 'left', marginLeft: 2 }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          vimeoEventDetail();
                        }}
                      >
                        Get Event Details
                      </Button>
                    </DialogActions>
                  </>
                )}

              {videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value &&
                watchEvent === EVENT_TYPE.ZOOM.value && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        name="zoomLink"
                        control={control}
                        rules={{
                          ...validationRules.REQUIRED,
                          ...validationRules.OPTIONAL_URL,
                          ...{ ...maxLengthValidation(255) },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Zoom Link Url"
                            type="text"
                            error={!!errors.zoomLink}
                            helperText={errors?.zoomLink?.message || null}
                            fullWidth
                            inputProps={{ maxLength: 255 }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name="zoomPassword"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Zoom Password"
                            type={showPassword ? 'text' : 'password'}
                            error={!!errors.zoomPassword}
                            helperText={errors?.zoomPassword?.message || null}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    <Iconify
                                      icon={
                                        showPassword
                                          ? 'eva:eye-fill'
                                          : 'eva:eye-off-fill'
                                      }
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

              {videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="eventStartDateTime"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Datetimepicker
                          id="eventStartDateTime"
                          name="eventStartDateTime"
                          label="Event Start Date"
                          value={value}
                          defaultValue={!dataId ? null : value}
                          onChange={onChange}
                          disabled={isDateDisable}
                          error={errors?.eventStartDateTime?.message || ''}
                          disablePast
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="eventDuration"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...validationRules.REQUIRED_NUMBER,
                        ...maxLengthValidation(3),
                      }}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            id="eventDuration"
                            name="eventDuration"
                            label="Event Duration (in minutes)"
                            type="number"
                            error={!!errors.eventDuration}
                            helperText={errors?.eventDuration?.message || null}
                            fullWidth
                            inputProps={{ min: 1 }}
                          />
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="location"
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...{ ...maxLengthValidation(255) },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Location"
                          type="location"
                          error={!!errors.location}
                          helperText={errors?.location?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 255 }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="recurringType"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <RecurringType
                          id="recurringType"
                          name="recurringType"
                          label="Recurring?"
                          defaultValue={value}
                          onChange={onChange}
                          validOptions={[1, 0]}
                          sx={{ width: '100%' }}
                          error={errors?.recurringType?.message || ''}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {watchRecurring === 1 && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="frequencyType"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <FrequencyTypes
                          id="frequencyType"
                          name="frequencyType"
                          label="Frequency Type"
                          defaultValue={value}
                          onChange={onChange}
                          sx={{ width: '100%' }}
                          error={errors?.frequencyType?.message || ''}
                        />
                      )}
                    />
                  </Grid>

                  {watchFrequency === FREQUENCY_TYPE.WEEKLY.value && (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <Controller
                          name="weekNumbers"
                          control={control}
                          rules={{
                            validate: {
                              required: () =>
                                (selectedWeeks && selectedWeeks.length > 0) ||
                                validationRules.REQUIRED.required.message,
                            },
                          }}
                          // eslint-disable-next-line no-unused-vars
                          render={({ field: { onChange, value } }) => (
                            <WeekNumber
                              id="weekNumbers"
                              name="weekNumbers"
                              label="Select Occurrence"
                              value={value}
                              defaultValue={value}
                              onChange={handleWeekChange}
                              validOptions={[0, 1, 2, 3, 4]}
                              selectedWeeks={selectedWeeks}
                              handleWeekChange={handleWeekChange}
                              sx={{ width: '100%' }}
                              error={errors?.weekNumbers?.message || ''}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Controller
                          name="day"
                          control={control}
                          rules={{
                            validate: {
                              required: () =>
                                (selectedDays && selectedDays.length > 0) ||
                                validationRules.REQUIRED.required.message,
                            },
                          }}
                          // eslint-disable-next-line no-unused-vars
                          render={({ field: { onChange, value } }) => (
                            <DayNames
                              id="day"
                              name="day"
                              label="Select Days"
                              value={value}
                              defaultValue={value}
                              onChange={handleDayChange}
                              validOptions={[0, 1, 2, 3, 4, 5, 6]}
                              selectedDays={selectedDays}
                              handleDayChange={handleDayChange}
                              sx={{ width: '100%' }}
                              error={errors?.day?.message || ''}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}

                  {watchFrequency === FREQUENCY_TYPE.MONTHLY.value && (
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        name="monthDays"
                        control={control}
                        rules={validationRules.REQUIRED}
                        render={({ field: { onChange, value } }) => (
                          <DateList
                            id="monthDays"
                            name="monthDays"
                            label="Select Month Days"
                            multiple
                            defaultValue={value}
                            onChange={onChange}
                            sx={{ width: '100%', my: 1 }}
                            error={errors?.monthDays?.message || ''}
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="recurringEndDateTime"
                      control={control}
                      rules={validationRules.REQUIRED}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          id="recurringEndDateTime"
                          name="recurringEndDateTime"
                          label="Recurring Ending On"
                          value={value}
                          defaultValue={value}
                          onChange={onChange}
                          sx={{ width: 600 }}
                          error={errors?.recurringEndDateTime?.message || ''}
                          disablePast
                          maxDate={maxDate}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider
                  textAlign="center"
                  variant="fullWidth"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${CONTENT_ALIAS.VIDEO_SINGULAR} Details (You can upload video and images from upload section)`}
                </Divider>
              </Grid>

              {videoTypeWatch !== VIDEO_TYPES.LIVE_EVENT.value && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="videoUrl"
                      control={control}
                      rules={{
                        ...validationRules.OPTIONAL_URL,
                        ...{ ...maxLengthValidation(500) },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Video Url"
                          type="text"
                          error={!!errors.videoUrl}
                          helperText={errors?.videoUrl?.message || null}
                          fullWidth
                          inputProps={{ maxLength: 500 }}
                          disabled={
                            !!dataId ||
                            videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name="duration"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Duration (In Seconds)"
                          type="number"
                          error={!!errors.duration}
                          helperText={errors?.duration?.message || null}
                          fullWidth
                          disabled={
                            !!dataId ||
                            videoTypeWatch === VIDEO_TYPES.LIVE_EVENT.value
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="thumbnail"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Thumbnail Url"
                      type="text"
                      error={!!errors.thumbnail}
                      helperText={errors?.thumbnail?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      {openPopUp && (
        <ConfirmPopup
          title=""
          message="Future event registrations might be drop. Do you want to continue?"
          onCancel={() => {
            setOpenPopUp(false);
            setFormSubmitted(false);
          }}
          onSuccess={handleSuccess}
        />
      )}

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditVideos.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default AddEditVideos;
