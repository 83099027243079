import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewCategory,
  viewCategoryById,
  updateCategoryById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
// import PaidType from '../form-elements/paid-type.component';
import { CONTENT_ALIAS } from '../../config/const.config';
// import FeaturedType from '../form-elements/featured-type.component';
// import CategoryType from '../form-elements/category-type.component';
// import Packages from '../form-elements/packages.component';
// import Groups from '../form-elements/groups.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCategories = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      // sectionId: null,
      // description: '',
      // categoryType: '',
      // instructor: '',
      // paidType: null,
      // packageId: '',
      // feedId: null,
      status: null,
      // featured: null,
      // whatsIncluded: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  // const watchPaid = watch('paidType');
  // const categoryTypeWatch = watch('categoryType');

  const saveNewData = (payload) => {
    createNewCategory(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.CATEGORY_SINGULAR} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateCategoryById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.CATEGORY_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      name: data.name,
      // sectionId: data.sectionId,
      // categoryType: data.categoryType,
      // instructor: data.instructor,
      // isPaid: categoryTypeWatch === CATEGORY_TYPE[2].id ? 1 : data.paidType,
      // feedId: Number(data.feedId),
      // packageId: Number(data.packageId),
      // isFeatured:
      //   data.categoryType === CATEGORY_TYPE[2].id ? 0 : Number(data.featured),
      // description: data.description,
      status: data.status,
      // whatsIncluded: data.whatsIncluded,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  // useEffect(() => {
  //   if (categoryTypeWatch === 'PROGRAM') {
  //     unregister('instructor');
  //     setValue('instructor', '');
  //   }
  // }, [categoryTypeWatch]);

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }
    viewCategoryById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('sectionId', res.data?.sectionId || '');
        setValue('categoryType', res.data?.categoryType || '');
        setValue('instructor', res.data?.instructor || '');
        setValue('packageId', res.data?.packageId || '');
        setValue('feedId', res.data?.feedId || '');
        setValue('paidType', res.data?.isPaid || 0);
        setValue('featured', res.data?.isFeatured || 0);
        setValue('description', res.data?.description || '');
        setValue('status', res.data?.status || 0);
        setValue('whatsIncluded', res.data?.whatsIncluded || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.CATEGORY_SINGULAR} Name`}
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="categoryType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <CategoryType
                      id="categoryType"
                      name="categoryType"
                      label="Category Type"
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.categoryType?.message || ''}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>

              {categoryTypeWatch === 'COURSE' && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="instructor"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Instructor"
                        type="text"
                        error={!!errors.instructor}
                        helperText={errors?.instructor?.message || null}
                        fullWidth
                        inputProps={{ maxLength: 500 }}
                      />
                    )}
                  />
                </Grid>
              )}

              {categoryTypeWatch !== 'COURSE' && (
                <Grid item xs={12} sm={6} md={6} />
              )} */}

              {/* {categoryTypeWatch !== CATEGORY_TYPE[2].id && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="paidType"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <PaidType
                        id="paidType"
                        name="paidType"
                        label="Paid?"
                        defaultValue={value}
                        onChange={onChange}
                        disabled={!!dataId}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.paidType?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}

              {watchPaid === 1 && categoryTypeWatch !== CATEGORY_TYPE[2].id && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="packageId"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Packages
                        id="packageId"
                        name="packageId"
                        label={`Select ${CONTENT_ALIAS.PACKAGE_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.packageId?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}

              {watchPaid === 1 && categoryTypeWatch !== CATEGORY_TYPE[2].id && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="feedId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Groups
                        id="feedId"
                        name="feedId"
                        label="Select Community Group"
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.feedId?.message || ''}
                        asPerSubscription
                      />
                    )}
                  />
                </Grid>
              )} */}

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              {/* {categoryTypeWatch !== CATEGORY_TYPE[2].id && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="featured"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <FeaturedType
                        id="featured"
                        name="featured"
                        label="Featured"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.featured?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="whatsIncluded"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Whats Included"
                      type="text"
                      error={!!errors.whatsIncluded}
                      helperText={
                        errors?.whatsIncluded?.message ||
                        'Each item should be on a new line to display as a bulleted list.'
                      }
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid> */}
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditCategories.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.objectOf.isRequired,
};

export default AddEditCategories;
