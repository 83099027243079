import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListSequence from '../../components/content/list-sequence.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import { CONTENT_ALIAS } from '../../config/const.config';

const ContentSequenceView = ({ type }) => {
  let pageTitle;

  if (type === CONTENT_ALIAS.CATEGORY_SINGULAR) {
    pageTitle = PAGE_TITLE_LIST.CONTENT_SEQUENCE_CATEGORY;
  } 
  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSequence type={type} />
    </AppLayout>
  );
};

ContentSequenceView.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ContentSequenceView;
