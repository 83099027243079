import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Groups module configurations
export const LISTING_COLUMNS = [
  // {
  //   id: 'thumbnail',
  //   label: 'Image Preview',
  //   width: '5%',
  //   align: 'center',
  //   dataKey: 'thumbnail',
  //   sortable: false,
  //   type: CELL_DATA_TYPES.IMAGE,
  // },
  {
    id: 'name',
    label: 'Name',
    width: '30%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  // {
  //   id: 'groupType',
  //   label: 'Group Type',
  //   width: '30%',
  //   align: 'left',
  //   dataKey: 'groupType',
  //   sortable: false,
  //   type: CELL_DATA_TYPES.TEXT,
  // },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   width: '10%',
  //   align: 'left',
  //   dataKey: 'status',
  //   sortable: true,
  //   type: CELL_DATA_TYPES.SWITCH,
  // },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '20%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
];

export const VIEW_GROUPS_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'lastMessageAt',
    label: 'Last Message Time',
    dataKey: 'lastMessageAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified Date',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
