// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_CATEGORIES: '/content/categories',
  CONTENT_VIDEOS: '/content/videos',
  CONTENT_VIDEOS_EVENT_REGISTRATIONS:
    '/content/videos/:id/events-registrations',
  CONTENT_MANAGE_RECURRING_EVENTS:
    '/content/videos/:id/manage-recurring-events',
  CONTENT_ADS: '/content/ads',
  CONTENT_SEQUENCE: '/content/sequence',
  CONTENT_SEQUENCE_VIDEOS: '/content/sequence/videos',
  CONTENT_SEQUENCE_CATEGORY: '/content/sequence/category',
  CONTENT_SEQUENCE_PROGRAM: '/content/sequence/programs',
  CONTENT_SEQUENCE_TAO_SONG: '/content/sequence/tao-songs',
  CONTENT_SEQUENCE_BLESSING: '/content/sequence/blessings',
  CONTENT_SEQUENCE_MEDITATION: '/content/sequence/meditation',
  CONTENT_GATHERINGS: '/content/gatherings',
  CONTENT_TEACHERS: '/content/leading-teachers',
  CONTENT_SEQUENCE_TESTIMONIAL_TABS: '/content/sequence/testimonials/tabs',
  CONTENT_SEQUENCE_TESTIMONIAL_CATEGORIES:
    '/content/sequence/testimonials/categories',
  CONTENT_TABS: '/content/tabs',
  CONTENT_TESTIMONIALS: '/content/testimonials',
  CONTENT_TESTIMONIALS_TEXT: '/content/testimonials/text-testimonials',
  CONTENT_USER_TESTIMONIALS_TEXT:
    '/content/testimonials/user-text-testimonials',
  CONTENT_USER_TESTIMONIALS_VIDEO:
    '/content/testimonials/user-video-testimonials',
  CONTENT_ARTICLE: '/content/article',
  SUBSCRIBERS: '/subscribers',
  GROUPS: '/groups',
  GROUPS_MANAGE_MEMBERS: '/groups/:id/manage-members',
  NOTIFICATIONS: '/notifications',
  SALUTATION: '/salutation',
  REPORTS_OVERVIEW_VIDEOS: '/reports/overview/videos',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_SESSIONS: '/reports/sessions',
  REPORTS_LIVE_EVENT: '/reports/live-event',
  REPORTS_PROGRAMS: '/reports/purchase-report',
  LOGOUT: '/logout',
  CONTENT_RESOURCES: '/content/resources',
  CONTENT_RECORDED_MESSAGE: '/content/recorded',
  CONTENT_RECORDED_VIDEOS: '/content/recorded/videos',
  CONTENT_RECORDED_AUDIOS: '/content/recorded/audios',
  CONTENT_LINK_SECTION_ITEMS: '/content/recorded/link-section-items',
};

export default ROUTE_PATH;
