export const MULTIPLE_CAT_PER_VIDEO = false;
export const MULTIPLE_GROUP_PER_VIDEO = false;
export const SHOW_OVERVIEW_DASHBOARD = true;
export const SHOW_REALTIME_DASHBOARD = false;
export const SHOW_SUBSCRIBERS = true;
export const SHOW_GROUPS = true;
export const SHOW_NOTIFICATIONS = true;
export const SHOW_SALUTATION = false;
export const SHOW_CONTENT_CATEGORIES = true;
export const SHOW_CONTENT_VIDEOS = true;
export const SHOW_CONTENT_GATHERINGS = true;
export const SHOW_LEADING_TEACHERS = true;
export const SHOW_CONTENT_RESOURCES = true;
export const SHOW_CONTENT_SEQUENCE = true;
export const SHOW_TESTIMONIALS = true;
export const SHOW_REPORTS_OVERVIEW_VIDEOS = true;
export const SHOW_REPORTS_LIVE_USERS = true;
export const SHOW_REPORTS_WATCH_SESSIONS = true;
export const SHOW_REPORTS_SUBSCRIPTIONS = true;
export const SHOW_REPORTS_LIVE_EVENT = true;
export const SHOW_REPORTS_LIVE_SESSIONS = true;
export const SHOW_RECORD_MESSAGE = true;

// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__ssft_admin_t';
export const LS_ADMIN_USER_NAME = '__ssft_admin_n';
export const LS_ADMIN_USER_EMAIL = '__ssft_admin_e';
export const LS_ADMIN_USER_ROLE = '__ssft_admin_r';
export const LS_ADMIN_USER_ID = '__ssft_admin_i';

export const TYPE_LABELS = {
  // 0: 'Text',
  1: 'Link',
  // 2: 'Audio',
};

export const TYPE_LABELS_KEYS = {
  // Text: 0,
  Link: 1,
  // Audio: 2,
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  3: 'Draft',
  4: 'Published',
  5: 'Requested Delete',
  6: 'Pendig For Approval',
  7: 'Approved',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
  13: 'Cancelled',
  14: 'Pending',
  15: 'Approved',
  16: 'Denied',
  17: 'Purchased',
};

export const VIDEO_TYPES = {
  TRAINING: {
    value: 'TRAINING',
    label: 'Training',
  },
  LIVE_EVENT: {
    value: 'LIVE_EVENT',
    label: 'Live Event',
  },
  INTRO: {
    value: 'INTRO',
    label: 'Intro',
  },
};

export const ELEMENT_TYPES = {
  EARTH: {
    value: 'EARTH',
    label: 'Earth',
  },
  FIRE: {
    value: 'FIRE',
    label: 'Fire',
  },
  WOOD: {
    value: 'WOOD',
    label: 'Wood',
  },
  METAL: {
    value: 'METAL',
    label: 'Metal',
  },
  WATER: {
    value: 'WATER',
    label: 'Water',
  },
};

export const POPULAR_CONTENT_TYPES = {
  PROGRAM: {
    value: 'PROGRAM',
    label: 'Program',
  },
  COURSE: {
    value: 'COURSE',
    label: 'Course',
  },
  TAO_SONG: {
    value: 'TAO_SONG',
    label: 'Tao Calligraphy Meditation',
  },
  LIVE_EVENT: {
    value: 'LIVE_EVENT',
    label: 'Live Event',
  },
  BLESSINGS: {
    value: 'BLESSINGS',
    label: 'Blessings',
  },
  MEDITATION: {
    value: 'MEDITATION',
    label: 'Love Peace Harmony',
  },
  TAO_SOUL_PROGRAM: {
    value: 'TAO_SOUL_PROGRAM',
    label: 'Tao Soul Program',
  },
};

export const FREQUENCY_TYPE = {
  WEEKLY: {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'Monthly (Days)',
  },
  // MONTHLY_WEEK_DAY: {
  //   value: 'MONTHLY_WEEK_DAY',
  //   label: 'Monthly (Week Days)',
  // },
};

export const EVENT_TYPE = {
  LIVE: {
    value: 'LIVE',
    label: 'Live',
  },
  ZOOM: {
    value: 'ZOOM',
    label: 'Zoom',
  },
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  3: 'warning',
  4: 'success',
  5: 'warning',
  6: 'warning',
  7: 'success',
  11: 'warning',
  12: 'warning',
  13: 'warning',
  14: 'warning',
  15: 'success',
  16: 'error',
  17: 'success',
};

export const LANGUAGES = [
  {
    value: 'hi',
    label: 'Hindi',
  },
  {
    value: 'fr',
    label: 'French',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'zh',
    label: 'Chinese',
  },
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'nl',
    label: 'Dutch',
  },
  {
    value: 'es',
    label: 'Spanish',
  },
  {
    value: 'gu',
    label: 'Gujarati',
  },
];

export const CONTENT_ALIAS = {
  CATEGORY_SINGULAR: 'Category',
  CATEGORY_PLURAL: 'Category',
  TAO_SOUL_PROGRAM_SINGULAR: 'Tao Soul Program',
  PROGRAMS_SINGULAR: 'Program',
  PROGRAMS_PLURAL: 'Programs',
  COURSES: 'Courses',
  VIDEO_SINGULAR: 'Video',
  VIDEO_PLURAL: 'Videos',
  TRANSMISSION_ORGAN_SINGULAR: 'Transmission Organ',
  TRANSMISSION_ORGAN_PLURAL: 'Transmission Organs',
  TRANSMISSION_PROGRAM_REQUEST_SINGULAR: 'Tao Soul Program Request',
  TRANSMISSION_PROGRAM_REQUEST_PLURAL: 'Tao Soul Program Requests',
  EVENT_SINGULAR: 'Event',
  EVENT_PLURAL: 'Events',
  PACKAGE_SINGULAR: 'Package',
  COURSES_SINGULAR: 'Course',
  COURSES_PLURAL: 'Courses',
  POPULAR_CONTENT_SINGULAR: 'Popular Content',
  RESOURCES_PLURAL: 'Resources',
  RESOURCES_SINGULAR: 'Resource',
  POPULAR_CONTENT_PLURAL: 'Popular Contents',
  TEACHER_SINGULAR: 'Leading Teacher',
  TEACHER_PLURAL: 'Leading Teachers',
  TAOSONG_SINGULAR: 'Tao Calligraphy Meditation',
  TAOSONG_PLURAL: 'Tao Calligraphy Meditations',
  MEDITATION_SINGULAR: 'Love Peace Harmony',
  MEDITATION_PLURAL: 'Love Peace Harmonys',
  LIVE_EVENT_SINGULAR: 'Live Event',
  BLESSING_SINGULAR: 'Blessing',
  BLESSING_PLURAL: 'Blessings',
  SQUARE_BANNER: 'Square Banner',
  HORIZONTAL_BANNER: 'Horizontal Banner',
  CONTENT: 'Content',
  CONTENT_TYPE: 'Content Type',
  ARTICLE_CATEGORY: 'Article Category',
  ARTICLE_CONTENT: 'Article Content',
  ARTICLE_CONTENT_PLURAL: 'Article Contents',
  ARTICLE: 'Article',
  ARTICLE_TYPE: 'Article Type',
  SUBSCRIBER_NOTES: 'Subscriber Notes',
  SUBSCRIPTION_NOTES: 'Subscription Notes',
  RECORDED_MESSAGE: 'Record',
  LINK_SECTION: 'Link Section',
  LINK_SECTION_ITEM: 'Link Section Item',
};

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  TRANSLATE: {
    value: 'translate',
    label: 'Translate',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CATEGORIES: {
    value: 'assign_books',
    label: `Assign ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  },
  UPLOAD_ASSETS: {
    value: 'upload_assets',
    label: 'Upload Assets',
  },
  UPLOAD_POSTER: {
    value: 'upload_poster',
    label: 'Upload Poster',
  },
  UPLOAD_VIDEO: {
    value: 'upload_video',
    label: 'Upload Video',
  },
  APPROVAL: {
    value: 'view and approve',
    label: 'View And Approve',
  },
  MANAGE_MEMBERS: {
    value: 'manage_members',
    label: 'Manage Members',
  },
  DOWNLOAD: {
    value: 'download',
    label: 'Download',
  },
  VIEW_TRANSACTION: {
    value: 'view transaction',
    label: 'View Transaction',
  },
  VIEW_REGISTRATIONS: {
    value: 'view Registrations',
    label: 'View Registrations',
  },
  LOGIN_AS_SUBSCRIBER: {
    value: 'login_as_subscriber',
    label: 'Login As Subscriber',
  },
  MANAGE_RECURRING_EVENTS: {
    value: 'manage_recurring_events',
    label: 'Manage Recurring Events',
  },
  MANAGE_NOTES: {
    value: 'manage_notes',
    label: 'Manage Notes',
  },
  ALLOW: {
    value: 'allow',
    label: 'Allow',
  },
  DENY: {
    value: 'deny',
    label: 'Deny',
  },
  MANAGE_TEACHERS: {
    value: 'manage_teachers',
    label: 'Manage Teachers',
  },
};

export const CATEGORY_TYPE = [
  {
    id: 'COURSE',
    label: 'Course',
  },
  {
    id: 'PROGRAM',
    label: 'Program',
  },
  {
    id: 'TAO_SOUL_PROGRAM',
    label: 'Tao Soul Program',
  },
];

export const PAID_TYPE = {
  0: 'Free',
  1: 'Paid',
};

export const FEATURED_TYPE = {
  0: 'Not Featured',
  1: 'Featured',
};

export const IS_TRIAL = {
  0: 'No',
  1: 'Yes',
};

export const ALLOW_COMMENT_TYPE = {
  0: 'No',
  1: 'Yes',
};

export const RECURRING_TYPE = {
  0: 'No',
  1: 'Yes',
};

export const WEEK_NAME = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const WEEK = {
  0: 'First Occurrence',
  1: 'Second Occurrence',
  2: 'Third Occurrence',
  3: 'Fourth Occurrence',
  4: 'Fifth Occurrence',
};

export const PROGRAM = {
  0: 'Course',
  1: 'Program',
  2: 'Tao Soul Program',
  3: 'Others',
};

export const OTHER_PROGRAM = {
  0: 'Tao Calligraphy Meditation',
  1: 'Love Peace Harmony',
  2: 'Blessing',
  3: 'Live Event',
};

export const ARTICLE_TYPE = [
  {
    value: 'TAO_SCIENCE',
    label: 'Tao Science',
  },
  {
    value: 'TAO_RESEARCH',
    label: 'Tao Research',
  },
];

export const STATUS_VALUE = [
  {
    value: 14,
  },
  {
    value: 15,
  },
  {
    value: 16,
  },
];

// Place it in CSS file
export const FOCUS_PALETTE_BGCOLOR = 'rgba(255, 255, 255, 0.25)';
