import React, { useEffect, useState, forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';
import { maxLengthValidation } from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  updateLeadingTeacherById,
  viewLeadingTeacherById,
} from '../../services/content.service';
import { LANGUAGES } from '../../config/const.config';
import ImageUpload from './image-upload.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const TranslateLeadingTeacher = ({ title, dataId, onCancel, onSuccess }) => {
  const isLeadingTeacher = true;
  const editor = useRef(null);
  const [bio, setBio] = useState({});
  const bioOptions = [
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    '|',
    'outdent',
    'indent',
    'align',
    '|',
    'hr',
    '|',
    'fullsize',
    'brush',
    '|',
    'table',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    '|',
    'source',
  ];
  const config = useMemo(
    () => ({
      defaultMode: 1,
      readonly: false,
      tabIndex: -1,
      toolbar: true,
      placeholder: 'Bio',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1,
      buttons: bioOptions,
      buttonsMD: bioOptions,
      buttonsSM: bioOptions,
      buttonsXS: bioOptions,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      minHeight: 350,
      useNativeTooltip: false,
      // language: 'de',
      // autofocus: true,
      // zIndex: 0,
      // toolbarAdaptive: false,
    }),
    []
  );
  const defaultValues = {};

  for (let i = 0; i < LANGUAGES.length; i += 1) {
    defaultValues[`teacherTitle_${LANGUAGES[i].value}`] = '';
    defaultValues[`specialities_${LANGUAGES[i].value}`] = '';
    defaultValues[`bio_${LANGUAGES[i].value}`] = '';
    defaultValues[`poster_${LANGUAGES[i].value}`] = '';
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const updateExistingData = (payload) => {
    updateLeadingTeacherById(dataId, payload, 'lang')
      .then(() => {
        onSuccess('Teacher details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {};
    for (let i = 0; i < LANGUAGES.length; i += 1) {
      payload[`teacherTitle_${LANGUAGES[i].value}`] =
        data[`teacherTitle_${LANGUAGES[i].value}`];
      payload[`specialities_${LANGUAGES[i].value}`] =
        data[`specialities_${LANGUAGES[i].value}`];
      payload[`bio_${LANGUAGES[i].value}`] =
        bio[`bio_${LANGUAGES[i].value}`] ||
        data[`bio_${LANGUAGES[i].value}`] ||
        '';
    }
    if (dataId) {
      updateExistingData(payload);
    }
  };
  const showUpdatedImage = () => {};
  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };
  const setImageUrlCallBack = (v) => {
    setVideoData((prevState) => ({
      ...prevState,
      [v.languageCode]: {
        ...prevState[v.languageCode],
        thumbnail: v.imageUrl,
      },
    }));
  };
  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewLeadingTeacherById(dataId, 'lang')
      .then((res) => {
        setVideoData(res.data);
        // Fill form values
        for (let i = 0; i < LANGUAGES.length; i += 1) {
          if (res.data[`${LANGUAGES[i].value}`]) {
            setValue(
              `teacherTitle_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].teacherTitle || ''
            );
            setValue(
              `specialities_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].specialities || ''
            );
            setValue(
              `bio_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].bio || ''
            );
            setValue(
              `poster_${LANGUAGES[i].value}`,
              res.data[`${LANGUAGES[i].value}`].posterUrl || ''
            );
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            {LANGUAGES.map((item, index) => (
              <Grid container spacing={2} key={`title${item.value}`}>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider
                    textAlign="center"
                    variant="fullWidth"
                    sx={{
                      fontWeight: 'bold',
                      mt: index === 0 ? 0 : 2,
                    }}
                  >
                    {item.label} Translation
                  </Divider>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name={`teacherTitle_${item.value}`}
                    control={control}
                    rules={{
                      ...maxLengthValidation(255),
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Teacher Title (${item.value})`}
                        type="text"
                        error={!!errors[`teacherTitle_${item.value}`]}
                        helperText={
                          errors[`teacherTitle_${item.value}`]?.message || null
                        }
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name={`specialities_${item.value}`}
                    control={control}
                    rules={{
                      ...{ ...maxLengthValidation(500) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={`Specialities (${item.value})`}
                        type="text"
                        multiline
                        minRows={3}
                        maxRows={3}
                        error={!!errors[`specialities${item.value}`]}
                        helperText={
                          errors[`specialities${item.value}`]?.message || null
                        }
                        fullWidth
                        inputProps={{ maxLength: 500 }}
                        sx={{
                          '& .MuiInputBase-root': {
                            overflowY: 'auto',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name={`bio_${item.value}`}
                    control={control}
                    render={({ field }) => (
                      <JoditEditor
                        {...field}
                        label={`Bio (${item.value})`}
                        ref={editor}
                        config={config}
                        tabIndex={-1}
                        onBlur={(newBio) =>
                          setBio((prev) => ({
                            ...prev,
                            [`bio_${item.value}`]: newBio,
                          }))
                        }
                        onChange={(newBio) =>
                          setBio((prev) => ({
                            ...prev,
                            [`bio_${item.value}`]: newBio,
                          }))
                        }
                      />
                    )}
                  />
                </Grid>

                <Box
                  component="section"
                  sx={{
                    p: 3,
                    ml: 2,
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    width: '100%',
                    marginTop: 3,
                    padding: '10px',
                  }}
                >
                  <ImageUpload
                    title="Poster"
                    dataId={dataId}
                    showToastMsg={showToastMsg}
                    setSnackbarInfo={setSnackbarInfo}
                    videoData={
                      videoData[item.value] || {
                        title: '',
                        thumbnail: '',
                        languageCode: item.value,
                      }
                    }
                    loading={loading}
                    showUpdatedImage={showUpdatedImage}
                    setImageUrlCallBack={setImageUrlCallBack}
                    isTranslation
                    isLeadingTeacher={isLeadingTeacher}
                  />
                </Box>
              </Grid>
            ))}
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

TranslateLeadingTeacher.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default TranslateLeadingTeacher;
