import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Video Testimonial module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '10%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'preview',
    label: '',
    width: '5%',
    align: 'right',
    dataKey: 'preview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'duration',
    label: 'Duration',
    width: '10%',
    align: 'left',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'testimonialBy',
    label: 'Testimonial By',
    width: '20%',
    align: 'left',
    dataKey: 'testimonialBy',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DOWNLOAD.value,
    label: ACTIONS.DOWNLOAD.label,
  },
];

export const VIEW_USER_TESTIMONIAL_VIDEOS_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'testimonialBy',
    label: 'Testimonial By',
    dataKey: 'testimonialBy',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'videoUrl',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR} URL`,
    dataKey: 'videoUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'thumbnail',
    label: 'Thumbnail',
    dataKey: 'thumbnail',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    dataKey: 'cdnStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIDEOJS_OPTIONS = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  sources: [
    {
      src: '',
    },
  ],
};
