// Define platforms list
export const PLATFORMS_LIST = [
  {
    id: 'WEB',
    title: 'Web',
  },
  {
    id: 'ADMIN',
    title: 'Admin',
  },
];

export const getPlatformLabel = (val) => {
  let label = '';

  PLATFORMS_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

// Valid admin roles
export const VALID_USER_ROLES = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
  SALE: 'SALE',
};
