import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewCommunityGathering,
  viewCommunityGatheringById,
  updateCommunityGatheringById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import Datetimepicker from '../form-elements/datetimepicker.component';
import ImageUpload from './image-upload.component';
import { validateSelectedImageFile } from '../../utils/file-validations.util';
import { getCurrentTimestamp } from '../../utils/datetime.util';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCommunityGathering = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      banner: '',
      findMoreBtnText: '',
      findMoreBtnUrl: '',
      bookTicketBtnText: '',
      bookTicketBtnUrl: '',
      status: null,
      gatheringStartDateTime: null,
      gatheringDuration: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [videoData, setVideoData] = useState({
    thumbnail: '',
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  // eslint-disable-next-line no-unused-vars
  const [renderImage, setRenderImage] = useState(false);
  const [fileData, setFileData] = useState({
    selectedFile: null,
    fileName: '',
  });
  const gatheringStartTimeRef = useRef(null);
  let gatheringStartDateTimeGMT = 0;
  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const saveNewData = (payload) => {
    createNewCommunityGathering(payload)
      .then(() => {
        onSuccess('Community Gathering added successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateCommunityGatheringById(dataId, payload)
      .then(() => {
        onSuccess('Community Gathering details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };
  const showToastMsg = (msgType, message) => {
    if (msgType === 'error') {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message,
      });
    }

    if (msgType === 'success') {
      setSnackbarInfo({
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const showUpdatedImage = (rerender) => {
    if (rerender) {
      setLoading(true);
    }
    setRenderImage(rerender);
  };

  const updateData = (data) => {
    const imageFormData = new FormData();

    imageFormData.append(
      'file',
      fileData.selectedFile ? fileData.selectedFile[0] : ''
    );
    imageFormData.append('imageType', 'thumbnail');
    imageFormData.append('selectedImageFileName', fileData.fileName || '');
    imageFormData.append('title', data.title);
    imageFormData.append('status', Number(data.status) || 0);
    imageFormData.append('banner', data.thumbnail || '');
    imageFormData.append('gatheringStartDate', gatheringStartDateTimeGMT);
    imageFormData.append('gatheringDuration', data.gatheringDuration);
    imageFormData.append('findMoreBtnText', data.findMoreBtnText);
    imageFormData.append('findMoreBtnUrl', data.findMoreBtnUrl);
    imageFormData.append('bookTicketBtnText', data.bookTicketBtnText);
    imageFormData.append('bookTicketBtnUrl', data.bookTicketBtnUrl);

    if (dataId) {
      updateExistingData(imageFormData);
    } else {
      saveNewData(imageFormData);
    }
  };

  const onFormSubmit = async (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    await sleep(100);

    gatheringStartDateTimeGMT =
      Date.parse(data.gatheringStartDateTime?.$d.toISOString()) / 1000;

    const currentTime = getCurrentTimestamp();
    if (
      gatheringStartDateTimeGMT <= currentTime &&
      gatheringStartDateTimeGMT !== gatheringStartTimeRef.current
    ) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message:
            'Community gathering start date must be greater than current time.',
        });
      }, 100);
      setFormSubmitted(false);
      return;
    }

    let validationError = '';

    if (fileData.selectedFile) {
      validationError = validateSelectedImageFile(fileData.selectedFile);

      if (validationError) {
        showToastMsg('error', validationError);
        setFormSubmitted(false);
        setLoading(false);
      } else {
        updateData(data);
      }
    } else {
      updateData(data);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewCommunityGatheringById(dataId)
      .then((res) => {
        gatheringStartTimeRef.current = res.data?.gatheringStartDateTime;
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('findMoreBtnText', res.data?.findMoreBtnText || '');
        setValue('findMoreBtnUrl', res.data?.findMoreBtnUrl || '');
        setValue('bookTicketBtnText', res.data?.bookTicketBtnText || '');
        setValue('bookTicketBtnUrl', res.data?.bookTicketBtnUrl || '');
        setValue('status', res.data?.status || 0);
        setValue(
          'gatheringStartDateTime',
          dayjs.unix(res.data?.gatheringStartDate) || null
        );
        setValue('gatheringDuration', res.data?.gatheringDuration || 0);
        setVideoData({
          ...videoData,
          thumbnail: res.data?.posterUrl || '',
        });

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(80) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Gathering Title"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 80 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="findMoreBtnText"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(50) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Find more button text"
                      type="text"
                      error={!!errors.findMoreBtnText}
                      helperText={errors?.findMoreBtnText?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="bookTicketBtnText"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(50) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Ticket button text"
                      type="text"
                      error={!!errors.bookTicketBtnText}
                      helperText={errors?.bookTicketBtnText?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="findMoreBtnUrl"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Find more button Url"
                      type="findMoreBtnUrl"
                      error={!!errors.findMoreBtnUrl}
                      helperText={errors?.findMoreBtnUrl?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="bookTicketBtnUrl"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Ticket button Url"
                      type="bookTicketBtnUrl"
                      error={!!errors.findMoreBtnUrl}
                      helperText={errors?.findMoreBtnUrl?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="status"
                      name="status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="gatheringStartDateTime"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="gatheringStartDateTime"
                      name="gatheringStartDateTime"
                      label="Community Gathering Start Date"
                      value={value}
                      defaultValue={!dataId ? null : value}
                      onChange={onChange}
                      error={errors?.gatheringStartDateTime?.message || ''}
                      disablePast
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="gatheringDuration"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...validationRules.REQUIRED_NUMBER,
                    ...maxLengthValidation(3),
                  }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <TextField
                        {...field}
                        label="Gathering Duration (in minutes)"
                        type="text"
                        error={!!errors.gatheringDuration}
                        helperText={errors?.gatheringDuration?.message || null}
                        sx={{ width: '100%' }}
                        fullWidth
                      />
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Box
                  component="section"
                  sx={{
                    p: 3,
                    border: '1px solid lightgrey',
                    borderRadius: '5px',
                    width: '100%',
                    marginTop: 3,
                  }}
                >
                  <ImageUpload
                    title="Thumbnail"
                    dataId={dataId}
                    showToastMsg={showToastMsg}
                    setSnackbarInfo={setSnackbarInfo}
                    videoData={videoData}
                    loading={loading}
                    showUpdatedImage={showUpdatedImage}
                    uploadFrom="form"
                    setFileData={setFileData}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditCommunityGathering.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditCommunityGathering;
