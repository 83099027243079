import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentVideosView from '../views/content/videos.view';
import ContentSequenceView from '../views/content/content-sequence.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import NotificationsView from '../views/settings/notification.view';
import SalutationView from '../views/settings/salutation.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentUserTestimonialTextView from '../views/content/user-testimonial-text.view';
import ContentUserTestimonialVideoView from '../views/content/user-testimonial-video.view';
import RecordedMessageView from '../views/content/recorded-message.view';
import {
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_SEQUENCE,
  SHOW_CONTENT_VIDEOS,
  SHOW_NOTIFICATIONS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SALUTATION,
  SHOW_SUBSCRIBERS,
  SHOW_TESTIMONIALS,
  SHOW_GROUPS,
  SHOW_CONTENT_GATHERINGS,
  SHOW_REPORTS_LIVE_EVENT,
  SHOW_CONTENT_RESOURCES,
  SHOW_RECORD_MESSAGE,
  SHOW_LEADING_TEACHERS,
  CONTENT_ALIAS,
} from '../config/const.config';
import GroupsView from '../views/groups/groups.view';
import ContentCommunityGatheringView from '../views/content/community-gathering.view';
import ManageMembersView from '../views/groups/manage-members.view';
import EventRegistrationsView from '../views/content/event-registrations.view';
import ManageRecuringEventView from '../views/content/manage-recuring-event.view';
import ReportsLiveEventView from '../views/reports/live-event.view';
import ResourcesContentView from '../views/content/resources-content.view';
import LeadingTeachersView from '../views/content/leading-teachers.view';

const AppRouter = ({ isLoggedIn }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIBERS) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIBERS,
        element: <SubscribersView />,
        exact: true,
      });
    }

    if (SHOW_GROUPS) {
      routeMappings.push({
        path: ROUTE_PATH.GROUPS,
        element: <GroupsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.GROUPS_MANAGE_MEMBERS,
        element: <ManageMembersView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_CATEGORIES) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES,
        element: <ContentCategoriesView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_EVENT_REGISTRATIONS,
        element: <EventRegistrationsView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_MANAGE_RECURRING_EVENTS,
        element: <ManageRecuringEventView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_RESOURCES) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_RESOURCES,
        element: <ResourcesContentView />,
        exact: true,
      });
    }
    if (SHOW_CONTENT_GATHERINGS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_GATHERINGS,
        element: <ContentCommunityGatheringView />,
        exact: true,
      });
    }
    if (SHOW_LEADING_TEACHERS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TEACHERS,
        element: <LeadingTeachersView />,
        exact: true,
      });
    }
    if (SHOW_RECORD_MESSAGE) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_RECORDED_VIDEOS,
        element: <RecordedMessageView type="VIDEO" />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_RECORDED_AUDIOS,
        element: <RecordedMessageView type="AUDIO" />,
        exact: true,
      });
    }
    if (SHOW_CONTENT_SEQUENCE) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_SEQUENCE_CATEGORY,
        element: <ContentSequenceView type={CONTENT_ALIAS.CATEGORY_SINGULAR} />,
        exact: true,
      });
    }

    if (SHOW_TESTIMONIALS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_USER_TESTIMONIALS_TEXT,
        element: <ContentUserTestimonialTextView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_USER_TESTIMONIALS_VIDEO,
        element: <ContentUserTestimonialVideoView />,
        exact: true,
      });
    }

    if (SHOW_NOTIFICATIONS) {
      routeMappings.push({
        path: ROUTE_PATH.NOTIFICATIONS,
        element: <NotificationsView />,
        exact: true,
      });
    }

    if (SHOW_SALUTATION) {
      routeMappings.push({
        path: ROUTE_PATH.SALUTATION,
        element: <SalutationView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_OVERVIEW_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_WATCH_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SESSIONS,
        element: <ReportsSessionsView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_EVENT) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_EVENT,
        element: <ReportsLiveEventView />,
        exact: true,
      });
    }

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accessRole: PropTypes.string.isRequired,
};

export default AppRouter;
