import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListCommunityGathering from '../../components/content/list-gatherings.component';

const ContentCommunityGatheringView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_COMMUNITY_GATHERINGS}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListCommunityGathering />
    </AppLayout>
  );
};

ContentCommunityGatheringView.propTypes = {};

export default ContentCommunityGatheringView;
