import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

export const LISTING_LEADING_TEACHERS_COLUMNS = [
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'posterUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'name',
    label: 'Name',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'teacherTitle',
    label: 'Teacher Title',
    width: '20%',
    align: 'left',
    dataKey: 'teacherTitle',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIEW_LEADING_TEACHERS_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'teacherTitle',
    label: 'Teacher Title',
    dataKey: 'teacherTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'bio',
    label: 'Bio',
    dataKey: 'bio',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'specialities',
    label: 'Specialities',
    dataKey: 'specialities',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'consultationLink',
    label: 'Consultation Link',
    dataKey: 'consultationLink',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'posterUrl',
    label: 'Poster Url',
    dataKey: 'posterUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_LEADING_TEACHERS_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];
