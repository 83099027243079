import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewData from '../table-elements/view-data.component';
import { viewRecordedMessageById } from '../../services/content.service';
import { VIEW_RECORDED_MESSAGE_COLUMNS } from '../../config/module-configs/recorded-message.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewRecordeMessage = ({ title, dataId, onCancel }) => {
  const columns = VIEW_RECORDED_MESSAGE_COLUMNS;

  const renderColumns = [];
  columns.forEach((item) => {
    renderColumns.push(item);
  });

  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    viewRecordedMessageById(dataId)
      .then((res) => {
        setRowData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description">
        {loading && <Loading />}

        {!loading && <ViewData columns={renderColumns} row={rowData} />}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ViewRecordeMessage.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ViewRecordeMessage;
