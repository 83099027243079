import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // FormControlLabel,
  IconButton,
  Button,
  TextField,
  Grid,
  // FormControl,
  // FormLabel,
  // RadioGroup,
  // Radio,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewGroup,
  updateGroupById,
  viewGroupById,
} from '../../services/content.service';
// import Status from '../form-elements/status.component';
import Subscribers from '../form-elements/subscribers.component';
// import FeaturedType from '../form-elements/featured-type.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditGroup = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    // watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      status: 1,
      chatAllowed: false,
      feedAllowed: false,
      memberSelectionFlag: 'Public',
      members: null,
      thumbnail: '',
    },
  });
  const moderator = true;
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const initialTypeRef = useRef(null);
  // const watchGroupType = watch('memberSelectionFlag');

  const saveNewData = (payload) => {
    createNewGroup(payload)
      .then(() => {
        onSuccess('Group created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateGroupById(dataId, payload)
      .then(() => {
        onSuccess('Group details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = async (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      name: data.name,
      description: data.description,
      status: data.status,
      chatAllowed: data.chatAllowed,
      feedAllowed: data.feedAllowed,
      role: data?.role?.map((item) => item.id) || [],
      isPublic: data.memberSelectionFlag === 'Public',
      isFeatured:
        data.memberSelectionFlag === 'Public' ? data.featured : 0 || 0,
      thumbnail: data.thumbnail,
    };
    if (dataId) {
      payload.typeChanged = data.memberSelectionFlag !== initialTypeRef.current;
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewGroupById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('description', res.data?.description || '');
        setValue('status', res.data?.status || 0);
        setValue('chatAllowed', res.data?.chatAllowed || false);
        setValue('feedAllowed', res.data?.feedAllowed || false);
        setValue(
          'memberSelectionFlag',
          res.data?.members?.length === 1 && res.data?.members[0] === -1
            ? 'Public'
            : 'selected'
        );
        setValue('role', res.data?.role || []);
        setValue('featured', res.data?.isFeatured || 0);
        setValue('thumbnail', res.data?.thumbnail);
        setLoading(false);
        initialTypeRef.current =
          res.data?.members?.length === 1 && res.data?.members[0] === -1
            ? 'Public'
            : 'selected';
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(150) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Group Name"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="status"
                      name="status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Controller
                  name="memberSelectionFlag"
                  control={control}
                  defaultValue="Public"
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <FormLabel id="demo-form-control-label-placement">
                        Group Type?
                      </FormLabel>
                      <RadioGroup
                        row
                        value={value}
                        onChange={onChange}
                        aria-labelledby="demo-form-control-label-placement"
                      >
                        <FormControlLabel
                          value="Public"
                          control={<Radio />}
                          label="Public"
                        />
                        <FormControlLabel
                          value="selected"
                          control={<Radio />}
                          label="As Per Subscription"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
              {watchGroupType === 'Public' && (
                <Grid item xs={6} sm={6} md={6}>
                  <Controller
                    name="featured"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <FeaturedType
                        id="featured"
                        name="featured"
                        label="Featured"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.featured?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )} */}
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Subscribers
                      id="role"
                      name="role"
                      label="Select Moderator"
                      multiple
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%', my: 1 }}
                      error={errors?.role?.message || ''}
                      moderator={moderator}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="thumbnail"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Thumbnail URL"
                      type="text"
                      error={!!errors.posterURL}
                      helperText={
                        errors?.posterURL?.message ||
                        "You can upload image from 'Upload Poster' option too from list."
                      }
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
              </Grid> */}
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditGroup.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditGroup;
