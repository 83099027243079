import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Stack, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/manage-members.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import {
  deleteGroupMemberById,
  getDataList,
} from '../../services/content.service';
import { ACTIONS } from '../../config/const.config';
import ConfirmPopup from '../common/confirm-popup.component';
import SearchList from '../table-elements/search-list.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ROUTE_PATH from '../../config/routes.config';
import editUrl from '../../utils/url-edit.util';
import AddEditMember from './add-edit-member.component';

const ListManageMembers = ({ conversationId }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const searchMemberRef = useRef(null);
  const navigate = useNavigate();
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const snackbarMessageCallback = (type, message) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });
    setTimeout(() => {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type,
        message,
      });
    }, 100);
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchGroups = () => {
    setOptions({
      ...options,
      q: searchMemberRef.current.value,
      reloadCounter: options.reloadCounter + 1,
      page: 0,
    });
  };

  const handleClearSearch = () => {
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
    });
    searchMemberRef.current.value = '';
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleActionSuccess = (message = '', actionCancel = true) => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    if (actionCancel) handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteGroupMemberById(conversationId, doAction.data.uid)
      .then(() => {
        handleActionSuccess('Member deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  useEffect(() => {
    let uri = `group-members?conversationId=${conversationId}`;
    uri += `&page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    }

    getDataList(uri)
      .then((res) => {
        let serialRow;
        if (res?.data?.rows) {
          serialRow = res.data.rows;
          serialRow = serialRow.map((tr, count) => ({
            ...tr,
            serial: options.page * options.rowsPerPage + count + 1,
          }));
        }

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.rows ? res?.data?.rows?.length : 0,
          rows: serialRow || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Link to={ROUTE_PATH.GROUPS}>
            <Button
              color="inherit"
              variant="contained"
              startIcon={<Iconify icon="ic:arrow-back" />}
              sx={{ marginRight: '5px' }}
            >
              Back
            </Button>
          </Link>

          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Add / Invite New Members
          </Button>

          <SearchList
            label="Members"
            handleSearch={handleSearchGroups}
            inputRef={searchMemberRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
          />
        </div>

        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onAction={handleActionSelect}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditMember
          title="Add / Invite New Members"
          dataId={0}
          conversationId={conversationId}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          snackbarMessageCallback={snackbarMessageCallback}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditMember
          title={`Edit Member Details - ${doAction.data.memberName}`}
          dataId={doAction.data.uid}
          conversationId={conversationId}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          snackbarMessageCallback={snackbarMessageCallback}
        />
      )}

      {doAction.action === ACTIONS.MANAGE_MEMBERS.value &&
        navigate(editUrl(ROUTE_PATH.GROUPS_MANAGE_MEMBERS, doAction.data.id))}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete Member"
          message={`Do you want to delete ${doAction.data.memberName} member? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ListManageMembers.propTypes = {
  conversationId: PropTypes.number.isRequired,
};

export default ListManageMembers;
