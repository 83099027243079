import React, { useEffect, useState, forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import JoditEditor from 'jodit-react';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewLeadingTeacher,
  updateLeadingTeacherById,
  viewLeadingTeacherById,
} from '../../services/content.service';
import { CONTENT_ALIAS } from '../../config/const.config';
import Status from '../form-elements/status.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
const AddEditLeadingTeacher = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  //   options,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      teacherTitle: '',
      bio: '',
      specialities: '',
      consultationLink: '',
      posterUrl: '',
      status: null,
    },
  });
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const editor = useRef(null);
  const [bio, setBio] = useState('');
  const bioOptions = [
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    '|',
    'outdent',
    'indent',
    'align',
    '|',
    'hr',
    '|',
    'fullsize',
    'brush',
    '|',
    'table',
    'link',
    'image',
    '|',
    'undo',
    'redo',
    '|',
    'source',
  ];
  const config = useMemo(
    () => ({
      defaultMode: 1,
      readonly: false,
      tabIndex: -1,
      toolbar: true,
      placeholder: 'Bio',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1,
      buttons: bioOptions,
      buttonsMD: bioOptions,
      buttonsSM: bioOptions,
      buttonsXS: bioOptions,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      minHeight: 350,
      useNativeTooltip: false,
    }),
    []
  );

  const saveNewData = (payload) => {
    createNewLeadingTeacher(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.TEACHER_SINGULAR} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateLeadingTeacherById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.TEACHER_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = {
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      teacherTitle: data.teacherTitle || '',
      bio: bio || '',
      specialities: data.specialities || '',
      consultationLink: data.consultationLink || '',
      posterUrl: data.posterUrl || '',
      status: data.status,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewLeadingTeacherById(dataId)
      .then((res) => {
        // Fill form values

        setValue('firstName', res.data?.firstName || '');
        setValue('lastName', res.data?.lastName || '');
        setValue('teacherTitle', res.data?.teacherTitle || '');
        setValue('bio', res.data?.bio || '');
        setBio(res.data?.bio || '');
        setValue('specialities', res.data?.specialities || '');
        setValue('consultationLink', res.data?.consultationLink || '');
        setValue('posterUrl', res.data?.posterUrl || '');
        setValue('status', res.data?.status || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      type="text"
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      type="text"
                      error={!!errors.lastName}
                      helperText={errors?.lastName?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="teacherTitle"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Teacher Title"
                      type="text"
                      error={!!errors.teacherTitle}
                      helperText={errors?.teacherTitle?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="consultationLink"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(255) },
                    validate: {
                      startsWithProd: (value) => {
                        if (!value) {
                          return true;
                        }
                        if (
                          value?.startsWith('https') ||
                          value?.startsWith('http')
                        ) {
                          return true;
                        }
                        return "URL must start with 'https' or 'http'";
                      },
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Consultation Link"
                      type="text"
                      error={!!errors.consultationLink}
                      helperText={errors?.consultationLink?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="specialities"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Specialities"
                      type="text"
                      multiline
                      minRows={3}
                      maxRows={3}
                      error={!!errors.specialities}
                      helperText={errors?.specialities?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                      sx={{
                        '& .MuiInputBase-root': {
                          overflowY: 'auto',
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="bio"
                  control={control}
                  render={({ field }) => (
                    <JoditEditor
                      {...field}
                      label="Bio"
                      ref={editor}
                      config={config}
                      tabIndex={-1}
                      onBlur={(newBio) => setBio(newBio)}
                      onChange={(newBio) => setBio(newBio)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="posterUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Poster Url"
                      type="text"
                      error={!!errors.posterUrl}
                      helperText={
                        errors?.posterUrl?.message ||
                        "You can upload image from 'Upload Poster' option too from list."
                      }
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditLeadingTeacher.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default AddEditLeadingTeacher;
