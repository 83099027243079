import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/users/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updatePasswordById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/users/${id}/update-password`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
