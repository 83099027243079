import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  Button,
  Grid,
  Box,
  CircularProgress,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Dropzone from 'react-dropzone';
import { validateSelectedPdfFile } from '../../utils/file-validations.util';
import { uploadPdfToBunnyCDN } from '../../services/content.service';

const PdfUpload = ({
  dataId,
  //   dataTitle,
  videoData,
  showToastMsg,
  showUpdatedImage,
  setSnackbarInfo,
  setPdfUrlCallBack,
  isTranslation,
  //   resource,
}) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesObj, setSelectedFilesObj] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showUploading, setShowUploading] = useState(false);

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSelectFiles = async (files) => {
    setSelectedFilesObj(files);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedPdfFile(files);
    if (validationError) {
      showToastMsg('error', validationError);
    }

    // Show file name below button
    for (let i = 0; i < files.length; i += 1) {
      setSelectedFileName(files[i].name);
    }
  };

  const handleUploadFiles = async (videoId, fileName) => {
    setDisableBtn(true);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedPdfFile(selectedFilesObj);
    if (validationError) {
      showToastMsg('error', validationError);
      setDisableBtn(false);
    } else {
      setShowUploading(true);
      const payload = {
        resourceId: videoId,
        file: selectedFilesObj[0],
        fileName,
        type: 'pdf',
        // languageCode: 'EN',
        // languageName: 'English',
      };
      try {
        const type = isTranslation
          ? `lang?lang=${videoData.languageCode}`
          : 'orig';
        const cdnResponse = await uploadPdfToBunnyCDN(payload, type);
        if (cdnResponse.data?.success) {
          showUpdatedImage(true);
          setPdfUrlCallBack({
            languageCode: videoData?.languageCode,
            pdfUrl: cdnResponse.data?.pdfUrl,
          });
          setSelectedFilesObj(null);
          setSelectedFileName('');
          showToastMsg('success', `File Uploaded Successfully!`);

          setDisableBtn(false);
        } else {
          showToastMsg('error', 'Something went wrong! Please try again.');
          setDisableBtn(false);
        }
      } catch (err) {
        showToastMsg(
          'error',
          err?.response?.data?.message ||
            'Something went wrong! Please try again.'
        );
        setDisableBtn(false);
      }
      setShowUploading(false);
    }
  };

  const handleFileNewTab = (fileUrl) => {
    window.open(`${fileUrl}?view=1`, '_blank');
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LinearProgressWithLabel = (props) => {
    const { value } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <Grid container spacing={2}>
      <Grid item sx={{ fontWeight: 'bold' }} xs={12} sm={12} md={12}>
        PDF:
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Grid style={{ marginBottom: '20px' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={!videoData.pdfUrl}
            onClick={() => handleFileNewTab(videoData?.pdfUrl)}
          >
            View File
          </Button>
        </Grid>
        <Grid item>
          <Box sx={{ fontWeight: 'bold' }}>PDF URL:&nbsp;</Box>
          <Box sx={{ fontSize: '15px' }}>{videoData.pdfUrl}</Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Grid item xs={12} sm={12} md={12}>
          <Dropzone
            onDrop={(acceptedFiles) => handleSelectFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <Box
                    component="section"
                    sx={{
                      p: 3,
                      border: '1px dashed grey',
                      borderRadius: '20px',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        Add/Replace PDF File
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Drag and drop PDF files here, or click to browse
                        </Grid>

                        <DialogActions sx={{ justifyContent: 'center' }}>
                          {!showUploading && (
                            <Button
                              component="label"
                              variant="contained"
                              disabled={disableBtn}
                              sx={{
                                backgroundColor: '#808080',
                              }}
                            >
                              Select PDF File
                            </Button>
                          )}
                          {showUploading && (
                            <Box
                              display="flex"
                              alignItems="center"
                              padding="6px 16px"
                              borderRadius="6px"
                              sx={{
                                backgroundColor: '#919eab3d',
                                color: '#919eabcc',
                              }}
                            >
                              <CircularProgress
                                disableShrink
                                size="18px"
                                sx={{ marginRight: 1, color: '#919eabcc' }}
                              />
                              <span>Uploading...</span>
                            </Box>
                          )}
                        </DialogActions>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {`${selectedFileName}`}
                    </Grid>
                  </Box>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={disableBtn}
            onClick={() => handleUploadFiles(dataId, selectedFileName)}
          >
            Upload PDF File
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

PdfUpload.propTypes = {
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  videoData: PropTypes.object.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  showUpdatedImage: PropTypes.func.isRequired,
  setSnackbarInfo: PropTypes.object.isRequired,
  resource: PropTypes.bool,
  isTranslation: PropTypes.bool,
  setPdfUrlCallBack: PropTypes.func,
};

PdfUpload.defaultProps = {
  resource: false,
  isTranslation: false,
  setPdfUrlCallBack: () => {},
};
export default PdfUpload;
