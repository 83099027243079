import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListLeadingTeachers from '../../components/content/list-leading-teachers.component';

const TeachersView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_TEACHERS}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListLeadingTeachers />
    </AppLayout>
  );
};

TeachersView.propTypes = {};

export default TeachersView;
